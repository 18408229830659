//@flow

import * as React from 'react';
import { connect } from "react-redux";
import { Field, formValueSelector} from 'redux-form';
import {Button, Form} from "@bootstrap-styled/v4";
import styled from 'styled-components';

import {PageLoader, Flex} from '../../../components';
import withExportForm from "./withExportForm";
import SelectField from "../../../fields/SelectField";
import {PageWrapper} from "../../../components-v3";
import ArticlesCoverflow from "./ArticlesCoverflow";

const StyledPageWrapper = styled(PageWrapper)`
    background-color: ${props => props.theme['$seashell']};
    border-bottom: 1px solid ${props => props.theme['$silver']};
`;

const StyledForm = styled(Form)`
    background-color: ${props => props.theme['$white']};
    border: 2px solid ${props => props.theme['$silver']};
    padding: ${props => props.theme['$spacers'][3]};
`;

type ExportProps = {
    handleSubmit: any => any,
    isFetching: boolean
}

class Export extends React.PureComponent<ExportProps> {

    get options() {
        const { profile } = this.props;

        const options = [
            {value: 21, label: 'HTML export'},
            {value: 20, label: 'RTF export'},
            {value: 22, label: 'CSV export'},
            {value: 23, label: 'CSV export for Excel'},
            {value: 25, label: 'XLSX - Excel'},
            {value: 'f2794495-75fc-11eb-8481-00163e8ef9eb', label: 'HTML Clip'},
        ];

            options.push({value: 24, label: 'JSON export'});

        return options;
    }

    render() {
        const { handleSubmit, isFetching, onCancel, onSelectArticle }  = this.props;

        if (isFetching) {
            return <PageLoader/>;
        }

        return <StyledPageWrapper className="align-items-center py-3">
            <StyledForm>
                <Field name="type" component={SelectField} label="Formát exportu" options={this.options}/>
                <Field name="maxResults" component={SelectField} label="Počet záznamov" options={[
                    {value: 10, label: '10'},
                    {value: 20, label: '20'},
                    {value: 50, label: '50'},
                    {value: 100, label: '100'},
                    {value: 200, label: '200'}
                ]}/>
                <Flex>
                    <Button onClick={handleSubmit}>Exportovať</Button>
                    <Button color="secondary" className="ml-3" onClick={onCancel}>Zrušiť</Button>
                </Flex>
            </StyledForm>
            <ArticlesCoverflow onSelect={onSelectArticle}/>
        </StyledPageWrapper>
    }
}

Export = withExportForm(Export);

export default connect(
    state => {
        const selector = formValueSelector('search');
        return {
            data: selector(state, 'filter.query', 'filter.date.from', 'filter.date.to', 'filter.sources', 'filter.date.type'),
            isFetching: state.createExport.isFetching,
            profile: state.profile
        }
    }
)(Export);
